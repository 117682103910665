
import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Todo from './components/Todo';
function App() {
  return (
   <>
   <NavBar/>
   <Todo/>
   <Footer/>
   </>
  );
}

export default App;
